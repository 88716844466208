<template>
	<div>
		<!-- 内容 -->
		<div class="contain flex">
			<div class="page-content">
				<div class="tabContent">
					<div class="title-bar">招考报名 > {{info.examName}}</div>
					<div class="bm-wapper">
						<div class="bm-bar">
							<step-bar :active="3"></step-bar>
						</div>
						<div class="bm-content">
							<div><img src="@/assets/img/success_1x.png"></img></div>
							<div class="content">报名信息填写完成！ <br />
								您的报名号是 {{info.ticketNo}} 请牢记。</div>
							<div class="btn-group">
								<el-button @click="gobty()">返回首页</el-button>
								<!-- <el-button type="primary" @click="gonext()">下载报名信息表</el-button> -->
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import StepBar from './stepbar.vue';
	import {
		GetExamSignUp,
	} from "@/api/home";
	export default {
		components: {
			StepBar
		},
		data() {
			return {
				info: {}
			};
		},
		created() {
			var id = this.$route.query.id;
			if (!isnull(id)) {
				this.Getinfo(id);
			}
		},
		methods: {
			gonext(e) {},
			gobty(e) {
				this.$router.push("/")
			},
			Getinfo(id) {
				GetExamSignUp({
					id: id
				}).then((res) => {
					if (res.success) {
						this.info = res.data;
					}
				});
			},
		},
	};
</script>
<style lang="scss" scoped>
	.title-bar {
		padding: 8px 20px;
		background-color: rgba(25, 144, 255, 0.1);
		font-size: 13px;
		color: #101010;
		font-weight: bold;
	}

	.page-content {
		width: 1200px;
		margin: 0 auto;
		padding: 20px 0;

		::v-deep .el-tabs__header {
			margin: 0;
		}

		::v-deep .el-tabs__item {
			padding: 0 80px;
		}
	}

	.tabContent {
		background: #FFFFFF;
		padding: 20px;

	}

	.bm-wapper {
		padding: 20px 0;
		display: flex;
	}

	.bm-bar {
		width: 180px;
	}

	.bm-content {
		flex: 1;
		padding: 0 65px;
		text-align: center;

		.title {
			color: rgba(16, 16, 16, 1);
			font-size: 15px;
			text-align: center;
			font-weight: bold;
		}

		.sub-title {
			color: rgba(16, 16, 16, 1);
			font-size: 14px;
			text-align: center;
			padding: 5px;
			font-weight: bold;
		}

		.content {
			font-size: 14px;
			color: #333;
			padding-bottom: 40px;
			padding-top: 20px;
		}
	}

	.btn-group {
		text-align: center;
	}
</style>
